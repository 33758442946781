enum Language {
  English,
  Spanish,
  Arabic,
  Portuguese,
  Turkish,
  Indonesian,
  German,
  Japanese,
  French,
  Vietnamese,
  Hindi,
  ChineseSimplified,
  Korean,
  Italian,
  Thai,
  Russian,
  Bengali,
}

export { Language };
