export enum EngineCategory {
  Scene = "scene",
  Character = "character",
  Creature = "creature",
  Animation = "animation",
  Expression = "expression",
  Location = "location",
  SetDressing = "set_dressing",
  Object = "object",
  Skybox = "skybox",
  Image_plane = "image_plane",
  Video_plane = "video_plane",
}
