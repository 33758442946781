import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons'

export function ModerationIcon() {
  return (
      <FontAwesomeIcon icon={faUsersCog} />
  );
}

