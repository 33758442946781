export enum AnimationType {
  ARKit = "ar_kit",
  MikuMikuDance = "miku_miku_dance",
  MikuMikuDanceARKit = "miku_miku_dance_ar_kit",
  Mixamo = "mixamo",
  MixamoARKit = "mixamo_ar_kit",
  MocapNet = "mocap_net",
  MocapNetARKit = "mocap_net_ar_kit",
  MoveAI = "move_ai",
  MoveAIARKit = "move_ai_ar_kit",
  Rigify = "rigify",
  RigifyARKit = "rigify_ar_kit",
  Rokoko = "rokoko",
  RokokoARKit = "rokoko_ar_kit",
}
