export interface Pagination {
  current: number,
  total_page_count: number
}

export interface LazyPagination {
  cursor_is_reversed: boolean,
  maybe_next: string,
  maybe_previous: string
}

export enum FetchStatus {
  paused,
  ready,
  in_progress,
  success,
  error
}