export enum WeightType {
  TT2 = "tt2",
  GPT_SO_VITS = "gpt_so_vits",
  TT2_5 = "tacotron2.5",
  HIFIGAN_TT2 = "hifigan_tt2",
  RVCv2 = "rvc_v2",
  SD_15 = "sd_1.5",
  SDXL = "sdxl",
  SVC = "so_vits_svc",
  LORA = "loRA",
  VALL_E = "vall_e",
  NONE = "none",
}
